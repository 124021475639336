<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import draggable from 'vuedraggable';
// import moment from 'moment';
import Cookies from "js-cookie";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Tambah Agenda Rapat",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    draggable,
  },
  data() {
    return {
      title: "Tambah Agenda Rapat",
      items: [
        {
          text: "Administrasi Rapat",
          href: "/",
        },
        {
          text: "Absensi Rapat Komisaris",
          href: "/administrasi/rapat-komisaris",
        },
        {
          text: "Tambah",
          active: true,
        },
      ],

      selectedItem: null,
      // variable Page Table
      loadingTable: true,
      dataTable: [],
      pagingTable: [],
      showDataToTable: "",
      showDataFromTable: "",
      totalDataTable: "",
      currentTablePage: "",
      searchDataTable: "",

      status: "ENABLE",
      master_divisi: [],
      master_komite: [],
      master_komite_selected: "",
      divisi_item: [],
      showLokasiLainnya: false,
      ar_lokasi:"",
      ar_tanggal: "",
      ar_jumlah: "",
      drag: false,
      access_token_brigate: Cookies.get("access_token_brigate"),
    };
  },
  mounted() {
    this.getDivisi();
    this.getKomite();
  },
  methods: {
    getDivisi() {
      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/master/divisi?status=" +
            self.status
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_divisi = response_data.list_data.data;
          }
        });
    },
    getKomite() {
      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/master/komite?status=" +
            self.status +
            "&role=26"
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_komite = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    lokasiRapatChange(){
      let self = this;
      if(self.ar_lokasi == 'Lainnya'){
        self.showLokasiLainnya = true;
      }else{
        self.showLokasiLainnya = false;
      }
    },
    addagendarapat() {
      this.divisi_item.push({
        agenda_rapat: "",
        jam_mulai: "",
        jam_selesai: "",
        div_pemateri: [],
        div_pendamping: [],
      });
    },
    edit(index) {
      this.selectedItem = index;
    },
    remove_divisi_item(index) {
      this.divisi_item.splice(index, 1);
    },
    save() {
      this.selectedItem = null;
    },
    StoreData() {
      let self = this;

      if(self.master_komite_selected == "" || self.ar_tanggal == "" || self.ar_lokasi == "" || self.ar_jumlah == "" || self.divisi_item.length == 0){
        Swal.fire({
          icon: 'error',
          title: 'Gagal!',
          text: "Pastikan formulir terisi lengkap",
        });
        return false;
      }

      for (let i = 0; i < self.divisi_item.length; i++) {
        if(self.divisi_item[i].agenda_rapat == '' || self.divisi_item[i].jam_mulai == '' || self.divisi_item[i].jam_selesai == '' || self.divisi_item[i].div_pemateri.length == 0 || self.divisi_item[i].div_pendamping.length == 0){
          Swal.fire({
            icon: 'error',
            title: 'Gagal!',
            text: "Pastikan formulir agenda rapat terisi lengkap",
          });
        return false;
        }
      }

      var mk_id = self.master_komite_selected?.mk_id;
      if(mk_id){
        mk_id = self.master_komite_selected?.mk_id;
      }else{
        mk_id = '';
      }
      if(self.ar_jumlah > 100){
        Swal.fire({
          icon: "warning",
          title: "Peringatan",
          text: "Jumlah kuorum melebihi 100",
        });
        return false;
      }
      Swal.fire({
          title: '<i class="fas fa-spinner fa-spin"></i>',
          text: "Loading...",
          showConfirmButton: false,
      });
      var data = JSON.stringify({
        "idSessionUser": Cookies.get("session_id"),
        "arTipeRapat": "komite_komisaris",
        "arTanggal": self.ar_tanggal,
        "arLokasi": self.ar_lokasi,
        "arLokasiLainnya": self.ar_lokasi_lainnya,
        "arJumlah": self.ar_jumlah,
        "arMkId": mk_id,
        "agendaRapat": JSON.stringify(self.divisi_item)
      });

      var config = {
        method: 'post',
        url: process.env.VUE_APP_BRIGATE + 'administrasirapat/storeabsensirapatkomisaris',
        headers: { 
          'X-BRIGATE-API_KEY': 'b0e6ebef-7fee-4d8d-ada3-2a2d16e4bdeb', 
          'Authorization': 'Bearer ' + this.access_token_brigate, 
          'Content-Type': 'application/json'
        },
        data : data
      };

        axios(config)
        .then(function (response) {
          var response_data = response.data; 
            if(response_data.code != 200){
                Swal.fire({
                icon: "error",
                title: "Oops...",
                html: response_data.message,
                });
            }else{
                let timerInterval;
                Swal.fire({
                    icon: "success",
                    title: "Berhasil",
                    text: "Anda akan diarahkan ke halaman administrasi rapat komisaris segera",
                    timer: 2000,
                    timerProgressBar: true,
                    showCancelButton: false,
                    showConfirmButton: false,
                    willClose: () => {
                        clearInterval(timerInterval);
                    },
                }).then((result) => {
                /* Read more about handling dismissals below */
                  if (result.dismiss === Swal.DismissReason.timer) {
                      // self.$router.push({ name: "vendor" });
                      Swal.close();
                      self.$router.push({ name: "rapat-komisaris" });
                  }
                });
            }
        })
        .catch(function (error) {
          console.log(error);
        });
        // Swal.fire({
        //     title: '<i class="fas fa-spinner fa-spin"></i>',
        //     text: "Loading...",
        //     showConfirmButton: false,
        // });
        // var FormData = require("form-data");
        // var data = new FormData();
        // data.append("id_session_user", localStorage.session_id);
        // data.append("ar_tipe_rapat", 'komite_komisaris');
        // data.append("ar_tanggal", self.ar_tanggal);
        // data.append("ar_lokasi", self.ar_lokasi);
        // data.append("ar_lokasi_lainnya", self.ar_lokasi_lainnya);
        // data.append("ar_jumlah", self.ar_jumlah);
        // data.append("ar_mk_id", mk_id);
        // data.append("agenda_rapat", JSON.stringify(self.divisi_item));

        // var config = {
        //     method: "post",
        //     url:
        //     process.env.VUE_APP_BACKEND_URL_VERSION + "/api/administrasirapat/storeabsensirapatkomisaris",
        //     data: data,
        // };
        // axios(config)
        //     .then(function (response) {
        //     var response_data = response.data; 
        //       if(response_data.code != 200){
        //           Swal.fire({
        //           icon: "error",
        //           title: "Oops...",
        //           html: response_data.message,
        //           });
        //       }else{
        //           let timerInterval;
        //           Swal.fire({
        //               icon: "success",
        //               title: "Berhasil",
        //               text: "Anda akan diarahkan ke halaman administrasi rapat komisaris segera",
        //               timer: 2000,
        //               timerProgressBar: true,
        //               showCancelButton: false,
        //               showConfirmButton: false,
        //               willClose: () => {
        //                   clearInterval(timerInterval);
        //               },
        //           }).then((result) => {
        //           /* Read more about handling dismissals below */
        //           if (result.dismiss === Swal.DismissReason.timer) {
        //               // self.$router.push({ name: "vendor" });
        //               Swal.close();
        //               self.$router.push({ name: "rapat-komisaris" });
        //           }
        //           });
        //       }
        //     })
        //     .catch((e) => {
        //     this.axiosCatchError = e.response.data.data;
        //     Swal.close();
        // });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="col-md-12">
              <div class="text-end">
                <router-link
                  :to="{ name: 'rapat-komisaris' }"
                  class="btn bg-warning btn-sm text-black"
                >
                  <i class="fa fa-arrow-left"></i> Kembali
                </router-link>
              </div>
            </div>
          </div>
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div class="row">
                <b-form-group
                  id="fieldset-horizontal"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="4"
                  label="Komite"
                  label-for="input-horizontal"
                >
                  <v-select
                    placeholder="Pilih Komite"
                    v-model="master_komite_selected"
                    label="mk_nama"
                    :options="master_komite"
                  ></v-select>
                </b-form-group>

                <!-- Tanggal Rapat -->
                <b-form-group
                  id="fieldset-horizontal"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="4"
                  label="Tanggal Rapat"
                  label-for="input-horizontal"
                >
                  <b-form-input
                    id="input-horizontal"
                    type="date"
                    v-model="ar_tanggal"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="fieldset-horizontal"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="4"
                  label="Lokasi Rapat"
                  label-for="input-horizontal"
                  placeholder="Pilih Lokasi Rapat"
                >
                  <v-select
                    id="input-horizontal"
                    :options="[
                      'Ruang Integrity / Gedung Kantor Pusat BRI',
                      'Work From Anywhere',
                      'Lainnya',
                    ]"
                    placeholder="-Pilih Lokasi Rapat-"
                    v-model="ar_lokasi"
                    @update:modelValue="lokasiRapatChange"
                  ></v-select>
                  <b-form-input
                    v-if="showLokasiLainnya"
                    id="input-horizontal"
                    type="text"
                    v-model="ar_lokasi_lainnya"
                    placeholder="Lokasi Lainnya"
                    class="mt-1"
                  ></b-form-input>
                </b-form-group>
                <!-- Jumlah Kuorum -->

                <b-form-group
                  id="fieldset-horizontal"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="4"
                  label="Jumlah Kuorum"
                  label-for="input-horizontal"
                >
                  <b-form-input
                    id="input-horizontal"
                    type="number"
                    v-model="ar_jumlah"
                    min="0"
                    max="100"
                  ></b-form-input>
                </b-form-group>

                <!-- Agenda Rapat -->
                <!-- <div>
                  <b-form-group
                    id="fieldset-horizontal"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    content-cols-sm
                    content-cols-lg="4"
                    label="Agenda Rapat"
                    label-for="input-horizontal"
                  >
                  </b-form-group>
                </div> -->

                <!-- tabel-->
                <div class="col-md-12 mt-2">
                  <p style="font-size: 40px"><strong>Agenda Rapat</strong></p>
                  <p style="color: red">
                    <i>Silahkan klik tombol + untuk menambahkan data</i>
                  </p>
                  <table
                    class="table mb-0 table-condensed table-hover table-bordered table-striped"
                  >
                    <thead>
                      <tr
                        style="background-color: #132d4a"
                        class="text-center text-white"
                      >
                        <th>Agenda Rapat</th>
                        <th>Jam Mulai</th>
                        <th>Jam Selesai</th>
                        <th>Divisi Pemateri</th>
                        <th>Divisi Pendamping</th>
                        <th>
                          <button
                            type="button"
                            class="btn btn-success btn-sm"
                            @click="addagendarapat"
                          >
                            <i class="fa fa-plus"></i>
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <draggable v-model="divisi_item" tag="tbody" item-key="name">
                      <template #item="{ element }">
                        <tr>
                          <td scope="row">
                            <input
                              type="text"
                              class="form-control"
                              v-model="element.agenda_rapat"
                              placeholder="Masukan Nama Agenda"
                            />  
                          </td>
                          <td>
                            <input
                              type="time"
                              class="form-control"
                              v-model="element.jam_mulai"
                            />
                          </td>
                          <td>
                            <input
                              type="time"
                              class="form-control"
                              v-model="element.jam_selesai"
                            />
                          </td>
                          <td>
                            <v-select
                              placeholder="Pilih Divisi Pemateri"
                              v-model="element.div_pemateri"
                              multiple="true"
                              label="md_nama"
                              :options="master_divisi"
                            ></v-select>
                          </td>
                          <td>
                            <v-select
                              placeholder="Pilih Divisi Pendamping"
                              v-model="element.div_pendamping"
                              multiple="true"
                              label="md_nama"
                              :options="master_divisi"
                            ></v-select>
                          </td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-danger btn-sm"
                              @click="remove_divisi_item(index)"
                            >
                              <i class="fa fa-minus"></i>
                            </button>
                          </td>
                        </tr>
                      </template>
                    </draggable>
                  </table>
                  <div v-if="selectedItem !== null">
                    <h3>Edit Item</h3>
                    <label>Name</label>
                    <input v-model="items[selectedItem].name" />
                    <label>Email</label>
                    <input v-model="items[selectedItem].email" />
                    <button @click="selectedItem = null">Cancel</button>
                    <button @click="save">Save</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6 text-end">
                  <button type="submit" class="btn btn-primary btn-sm">
                    <i class="fa fa-save"></i> SIMPAN
                  </button>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
